import React from 'react';

export const PrivacyPolicy = () => (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333' }}>
        <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>Privacy Policy</h1>

        <p style={{ marginBottom: '20px' }}>
            This Privacy Policy ("Policy") applies to PandaSwipe and Panda Swipe ("Company") and governs data collection and usage. 
            For the purposes of this Privacy Policy, unless otherwise noted, all references to the Company include{' '}
            <a href="https://www.pandaswipe.com/" style={{ color: '#007bff', textDecoration: 'none' }}>https://www.pandaswipe.com/</a>.
            The Company's application is a Credit card recommendations application. By using the Company application, you consent to the data 
            practices described in this statement.
        </p>

        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Collection of your Personal Information</h2>
        <p style={{ marginBottom: '15px' }}>
            We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to 
            provide certain personal information to us when you elect to use certain products or services. These may include:
        </p>

        <ul style={{ paddingLeft: '20px', marginBottom: '20px' }}>
            <li>Registering for an account</li>
            <li>Entering a sweepstakes or contest sponsored by us or one of our partners</li>
            <li>Signing up for special offers from selected third parties</li>
            <li>Sending us an email message</li>
            <li>Submitting your credit card or other payment information when ordering and purchasing products and services</li>
        </ul>

        <p style={{ marginBottom: '20px' }}>
            To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you 
            have requested from us. We also may gather additional personal or non-personal information in the future.
        </p>

        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Sharing Information with Third Parties</h2>
        <p style={{ marginBottom: '20px' }}>
            The Company does not sell, rent, or lease its customer lists to third parties. The Company may, from time to time, contact you on 
            behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique 
            personally identifiable information (email, name, address, phone number) is not transferred to the third party. The Company may 
            share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, 
            or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these 
            services to the Company, and they are required to maintain the confidentiality of your information.
        </p>

        <p style={{ marginBottom: '20px' }}>
            The Company may disclose your personal information, without notice, if required to do so by law or in the good faith belief that 
            such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on the Company or the site; 
            (b) protect and defend the rights or property of the Company; and/or (c) act under exigent circumstances to protect the personal 
            safety of users of the Company, or the public.
        </p>

        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Right to Deletion</h2>
        <p style={{ marginBottom: '20px' }}>
            Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
        </p>

        <ul style={{ paddingLeft: '20px', marginBottom: '20px' }}>
            <li>Delete your personal information from our records; and</li>
            <li>Direct any service providers to delete your personal information from their records.</li>
        </ul>

        <p style={{ marginBottom: '20px' }}>
            Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:
        </p>

        <ul style={{ paddingLeft: '20px', marginBottom: '20px' }}>
            <li>Complete the transaction for which the personal information was collected</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity</li>
            <li>Exercise free speech or comply with the California Electronic Communications Privacy Act</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research</li>
            <li>Enable solely internal uses that are reasonably aligned with your expectations</li>
            <li>Comply with an existing legal obligation</li>
        </ul>

        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Children Under Thirteen</h2>
        <p style={{ marginBottom: '20px' }}>
            The Company does not knowingly collect personally identifiable information from children under the age of 13. If you are under the 
            age of 13, you must ask your parent or guardian for permission to use this application.
        </p>

        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Email Communications</h2>
        <p style={{ marginBottom: '20px' }}>
            From time to time, the Company may contact you via email for the purpose of providing announcements, promotional offers, alerts, 
            confirmations, surveys, and/or other general communication. In order to improve our services, we may receive a notification when 
            you open an email from the Company or click on a link therein.
        </p>
        <p style={{ marginBottom: '20px' }}>
            If you would like to stop receiving marketing or promotional communications via email from the Company, you may opt out of such 
            communications by clicking on the unsubscribe button.
        </p>

        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Changes to This Statement</h2>
        <p style={{ marginBottom: '20px' }}>
            The Company reserves the right to change this Policy from time to time. For example, when there are changes in our services, 
            changes in our data protection practices, or changes in the law. When changes to this Policy are significant, we will inform you. 
            You may receive a notice by sending an email to the primary email address specified in your account, by placing a prominent 
            notice on our Panda Swipe, and/or by updating any privacy information. Your continued use of the application and/or services 
            available after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) agreement to abide and 
            be bound by that Policy.
        </p>

        <h2 style={{ fontSize: '20px', marginBottom: '15px' }}>Contact Information</h2>
        <p style={{ marginBottom: '20px' }}>
            The Company welcomes your questions or comments regarding this Policy. If you believe that the Company has not adhered to this 
            Policy, please contact the Company at:
        </p>

        <p style={{ marginBottom: '5px' }}>Panda Swipe</p>
        <p style={{ marginBottom: '5px' }}>4429 Marlborough Avenue</p>
        <p style={{ marginBottom: '5px' }}>San Diego, California 92116</p>

        <p style={{ marginBottom: '20px' }}>
            Email Address: <a href="mailto:help@pandaswipe.com" style={{ color: '#007bff', textDecoration: 'none' }}>help@pandaswipe.com</a>
        </p>
        <p style={{ marginBottom: '20px' }}>Phone Number: 13127749029</p>

        <p style={{ fontStyle: 'italic' }}>Effective as of September 09, 2024</p>
    </div>
);
